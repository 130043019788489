import Model from "../Model";

class Course extends Model {
    buildUrl ({ param }) {
        return ['courses', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'courses',
            data: form
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'courses/store',
            data: form
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'courses/update',
            data: form
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'courses/destroy',
            data: form
        });
    }

    async gainTimeLine(params){

        let getParams = '';

        for (let key in params) {
            getParams += (getParams ? '&' : '?');
            getParams += key + '=' + params[key];
        }

        return await this.request({
            method: 'GET',
            url: 'courses/gain-time-line' + getParams,
        });
    }

    async monthlySummary(form) {
        return await this.request({
            method: 'POST',
            url: 'courses/monthly-summary',
            data: form
        });
    }
}

export default new Course;